<template>
  <div class="trade-in">
    <div
      class="no-trade-in"
      v-if="!tradeIn.stockSlip"
    >
      <h2 class="title">Chọn phiếu thu cũ để liên kết</h2>
      <p class="desc">Bấm vào nút dưới đây để tìm kiếm phiếu thu cũ của khách hàng</p>
      <b-button
        size="sm"
        variant="primary"
        class="select-btn"
        @click="popupModal"
      >
        Chọn phiếu thu cũ
      </b-button>

      <img
        src="media/svg/illustrations/search.svg"
        class="mw-100 h-200px h-sm-325px searching-img"
        alt="file searching"
      />
    </div>

    <div
      v-else
      class="trade-in-card"
    >
      <h4 class="title">Phiếu thu cũ liên kết</h4>
      <div class="head">
        <div class="code">
          <span class="card-label">Mã phiếu</span>
          <span class="card-content">#{{ tradeIn.stockSlip.code }}</span>
        </div>
        <div class="created-date">
          <span class="card-label">Ngày tạo</span>
          <span class="card-content">{{ tradeIn.createdAt }}</span>
        </div>
        <div class="created-by">
          <span class="card-label">Người tạo</span>
          <span class="card-content">{{ tradeIn.createdByName}}</span>
        </div>
        <div class="stock-name">
          <span class="card-label">Kho nhập</span>
          <span class="card-content">{{ tradeIn.storeName }}</span>
        </div>
        <div class="total-amount">
          <span class="card-label">Giá mua cuối</span>
          <span class="card-content">{{ formatPrice(tradeIn.finalBuyingPrice) }}</span>
        </div>
        <div class="action">
          <b-button
            variant="primary"
            size="sm"
            @click="popupModal"
          >Thay đổi</b-button>
        </div>
      </div>
      <div class="note">
        <span class="card-label">Nội dung</span>
        <p>{{ tradeIn.note }}</p>
      </div>
      <div class="list-product">
        <span class="card-label">Danh sách sản phẩm:</span>
        <table>
          <thead>
            <tr>
              <th>Tên sản phẩm</th>
              <th>IMEI</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
              <th>Chiết khấu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ tradeIn.product.productName }}</td>
              <td>{{ tradeIn.productIMEI }}</td>
              <td>1</td>
              <td>{{ formatPrice(tradeIn.product.sellingPrice) }}</td>
              <td>{{ formatPrice(tradeIn.product.sellingPrice) }} </td>
              <td>{{ formatPrice(tradeIn.stockSlip.discountAmount) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr></tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div>
      <SearchTradeInModal
        :customerId="customerId"
        @change="onSearch($event)"
      />
    </div>
  </div>
</template>

<script>
import SearchTradeInModal from '@/view/components/bills/SearchTradeInModal.vue';
import { formatPrice } from '@/utils/common';
export default {
  props: {
    customerId: {
      type: Number,
    },
    tradeIn: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showChild: false,
    };
  },
  components: {
    SearchTradeInModal,
  },
  methods: {
    formatPrice,
    onSearch(event) {
      console.log('on search', event);
    },
    popupModal() {
      this.$bvModal.show('modal-search-trade-in');
    },
  },
};
</script>

<style lang="scss" scoped>
.no-trade-in {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  .title {
    font-size: 1.85rem;
    font-weight: 500;
    color: #181c32;
  }
  .desc {
    padding: 1.75rem 0;
    color: #b5b5c3;
    font-weight: 500;
    font-size: 1.25rem;
    width: 300px;
  }
  .select-btn {
    width: 136px;
    height: 46px;
    font-size: 1.075rem;
    font-weight: 500;
  }
  .searching-img {
    padding-top: 3.75rem;
    object-fit: cover;
  }
}
.trade-in-card {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  .card-label {
    color: #a1a5b7;
    --bs-text-opacity: 1;
    font-weight: 500;
  }
  .title {
    color: #3f4254;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      .card-content {
        font-size: 1rem;
        font-weight: 500;
        color: #181c32;
      }
    }
  }
  .note {
    margin-top: 20px;
  }
  .list-product {
    margin-top: 20px;
    table {
      width: 100%;
      th {
        background: rgba(77, 89, 149, 0.06);
      }
    }
    table,
    th,
    td {
      border: 1px solid black;
      padding: 8px;
    }
  }
}
</style>