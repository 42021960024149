<template>
  <div>
    <b-form-group :disabled="disabled">
      <label>Điện thoại người nhận bảo hành:</label>
      <Autosuggest
        :model="warrantyCustomerModel.customerMobile"
        :suggestions="filteredOptionsCustomer"
        :placeholder="'Nhập SĐT người bảo hành'"
        :limit="10"
        @select="onSelectedCustomer"
        @change="onInputChangeCustomer">
        <template #custom="{suggestion}">
          <div>
            <div class="d-flex flex-column">
              <span class="text-primary mb-1" v-if="suggestion.item.phoneNo"
                >({{ suggestion.item.phoneNo }})</span
              >
              <span v-if="suggestion.item.fullName">{{
                suggestion.item.fullName
              }}</span>
              <span v-if="suggestion.item.message">
                {{ suggestion.item.message }}
              </span>
            </div>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label> Tên: </label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="warrantyCustomerModel.customerName"
        placeholder="Tên người nhận bảo hành"
      ></b-form-input>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Họ:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="warrantyCustomerModel.customerLastName"
        placeholder="Họ người nhận bảo hành"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { TIME_TRIGGER } from '../../../utils/constants';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  props: ['billData', 'disabled'],
  components: {
    Autosuggest
  },
  data() {
    return {
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      inputSearchCustomerProp: {
        class: 'autosuggest__input',
        placeholder: 'SĐT người nhận bảo hành',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      warrantyCustomerModel: {
        customerId: null,
        customerMobile: '',
        customerName: '',
        customerLastName: '',
      },
    };
  },
  computed: {},
  mounted() {},
  watch: {
    warrantyCustomerModel: {
      handler: function (newValue) {
        this.billData.warrantyCustomerModel = newValue;
      },
      deep: true,
    },
  },
  methods: {
    onSelectedCustomer(option) {
      this.warrantyCustomerModel.customerId = option.item.id;
      this.warrantyCustomerModel.customerMobile = '';
      this.getCustomerInfoById();
    },
    onInputChangeCustomer(text) {
      if (!text) {
        text = '';
      }
      this.warrantyCustomerModel.customerMobile = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer[0].data];
      this.debounceInput();
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      const text = this.warrantyCustomerModel.customerMobile
        ? this.warrantyCustomerModel.customerMobile.trim()
        : null;
      if (!text) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
        this.optionsCustomer[0].data = data.data.data;
        if (this.optionsCustomer[0].data.length > 0) {
          this.filteredOptionsCustomer = [...this.optionsCustomer[0].data];
        } else {
          this.filteredOptionsCustomer = [
            { 
              message: 'SĐT không có trong hệ thống' 
            }
          ];
        }}
      );
    },
    getCustomerInfoById() {
      ApiService.get(`customer/${this.warrantyCustomerModel.customerId}`).then(
        (data) => {
          const customerInfo = data.data.data;
          this.warrantyCustomerModel.customerMobile = customerInfo.phoneNo;
          this.warrantyCustomerModel.customerName = customerInfo.fullName;
          this.warrantyCustomerModel.customerLastName = customerInfo.lastName
            ? customerInfo.lastName
            : '';
        }
      );
    },
    getListOrderSource() {
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listCustomerSource = data.data.data;
      });
    },
  },
};
</script>

<style>

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>